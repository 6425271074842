import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Particle from "../Particle";
import servicesData from "./ServicesData";
import "./Services.css";

function Services() {
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState(null);
  const [selectedTier, setSelectedTier] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Reset selections when component unmounts or when navigating away
  useEffect(() => {
    return () => {
      setSelectedService(null);
      setSelectedTier(null);
      setShowPhoneModal(false);
    };
  }, []);

  const handleServiceSelection = (service, tier) => {
    // Prevent selecting if modal is already open
    if (showPhoneModal) return;

    setSelectedService(service);
    setSelectedTier(tier);
    setShowPhoneModal(true);
  };

  const handleModalClose = () => {
    setShowPhoneModal(false);
    setPhoneNumber('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!selectedService || !selectedTier || !phoneNumber.trim()) {
      alert('Please fill in all required fields');
      return;
    }

    const serviceRequest = {
      service: selectedService.title,
      tier: selectedTier,
      phoneNumber: phoneNumber.trim(),
      timestamp: new Date().toISOString()
    };

    // Show success modal first
    setShowPhoneModal(false);
    setShowSuccessModal(true);

    // After 3 seconds, navigate to contact page
    setTimeout(() => {
      setShowSuccessModal(false);
      setPhoneNumber('');
      setSelectedService(null);
      setSelectedTier(null);
      
      navigate('/about', { 
        state: { 
          serviceRequest: serviceRequest 
        }
      });
    }, 60000);
  };

  return (
    <Container fluid className="project-section">
      <Helmet>
        <title>Professional Services | Austin Elongo</title>
        <meta name="description" content="Professional web development, mobile app development, SEO, graphics design, and video editing services in Cameroon. Choose from our Black and Gold tiers." />
        <meta name="keywords" content="web development, mobile apps, SEO, graphics design, video editing, Cameroon, professional services" />
        <meta property="og:title" content="Professional Services | Austin Elongo" />
        <meta property="og:description" content="Professional web development, mobile app development, SEO, graphics design, and video editing services in Cameroon." />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://austinelongo.click/services" />
      </Helmet>
      
      <Particle />
      
      <Container>
        <h1 className="project-heading">
          Professional <strong className="purple">Services</strong>
        </h1>
        <p style={{ color: "white" }}>
          Choose from our range of professional services tailored to your needs
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {servicesData.map((service, index) => (
            <Col key={index} md={6} className="project-card">
              <div className="service-card">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                
                <div className="service-tiers">
                  <div className="service-tier">
                    <h4 className="tier-name">Black Tier</h4>
                    <p className="tier-price">{service.tiers.black.price}</p>
                    <ul className="tier-features">
                      {service.tiers.black.features.map((feature, idx) => (
                        <li key={idx} className="tier-feature">{feature}</li>
                      ))}
                    </ul>
                    <button
                      className="select-button"
                      onClick={() => handleServiceSelection(service, 'Black')}
                      aria-label={`Select Black Tier for ${service.title}`}
                    >
                      Select Black Tier
                    </button>
                  </div>
                  
                  <div className="service-tier">
                    <h4 className="tier-name">Gold Tier</h4>
                    <p className="tier-price">{service.tiers.gold.price}</p>
                    <ul className="tier-features">
                      {service.tiers.gold.features.map((feature, idx) => (
                        <li key={idx} className="tier-feature">{feature}</li>
                      ))}
                    </ul>
                    <button
                      className="select-button"
                      onClick={() => handleServiceSelection(service, 'Gold')}
                      aria-label={`Select Gold Tier for ${service.title}`}
                    >
                      Select Gold Tier
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      {showPhoneModal && (
        <div className="phone-modal" role="dialog" aria-modal="true">
          <div className="modal-content">
            <button 
              className="close-button" 
              onClick={handleModalClose}
              aria-label="Close modal"
            >
              ×
            </button>
            <h3>Enter Your Details</h3>
            <form onSubmit={handleSubmit}>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter your phone number"
                required
                aria-label="Phone number"
                pattern="[0-9]+"
              />
              <div className="modal-buttons">
                <button type="submit">Continue</button>
                <button type="button" onClick={handleModalClose}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <div className="success-modal" role="dialog" aria-modal="true">
          <div className="success-content">
            <h3>Thank You!</h3>
            <p>Your service request has been received.</p>
            <p>Our engineer will contact you shortly at: {phoneNumber}</p>
            <div className="loading-spinner"></div>
          </div>
        </div>
      )}
    </Container>
  );
}

export default Services; 