import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import { FaDownload } from "react-icons/fa";
import Toolstack from "./Toolstack";
import { Helmet } from "react-helmet";

function About() {
  const location = useLocation();
  const serviceRequest = location.state?.serviceRequest;

  return (
    <Container fluid className="about-section">
      <Helmet>
        <title>About Austin Elongo | Full Stack Developer</title>
        <meta name="description" content="Learn about Austin Elongo, a Full Stack Developer specializing in web development, mobile apps, and digital solutions in Cameroon." />
        <meta name="keywords" content="Austin Elongo, Full Stack Developer, Web Developer, Mobile App Developer, Cameroon Developer, Software Engineer" />
        <meta property="og:title" content="About Austin Elongo | Full Stack Developer" />
        <meta property="og:description" content="Professional Full Stack Developer with expertise in web development, mobile apps, and digital solutions." />
        <meta property="og:type" content="profile" />
        <link rel="canonical" href="https://austinelongo.click/about" />
      </Helmet>
      <Particle />
      <Container>
        {serviceRequest && (
          <div className="service-confirmation">
            <h2>Service Request Received!</h2>
            <div className="request-details">
              <p>Service: {serviceRequest.service}</p>
              <p>Tier: {serviceRequest.tier}</p>
              <p>We will contact you at: {serviceRequest.phoneNumber}</p>
            </div>
            <div className="contact-info">
              <p>For immediate assistance:</p>
              <p>WhatsApp: <a href="https://wa.me/237677699999">+237 677 699 999</a></p>
            </div>
          </div>
        )}

        <Row className="about-header">
          <Col
            md={7}
            className="about-content animate__animated animate__fadeIn"
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know Who <strong className="purple">I AM</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            className="about-img animate__animated animate__fadeInRight"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>

        <div className="section-divider">
          <h2 className="section-title">Professional Skills</h2>
        </div>

        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />

        <div className="section-divider">
          <h2 className="section-title">Development Tools</h2>
        </div>

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        <div className="resume-section">
          <h2>Want to know more?</h2>
          <p>Download my resume to learn about my experience and qualifications</p>
          <button className="resume-btn">
            <FaDownload className="download-icon" /> Download Resume
          </button>
        </div>

        <Github />
      </Container>
    </Container>
  );
}

export default About;
