import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/dev cartoon.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import { FaArrowDown } from "react-icons/fa";
import { Link } from "react-router-dom";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content hero-section">
          <Row>
            <Col md={7} className="home-header animate__animated animate__fadeIn">
              <div className="hero-intro">
                <h1 className="heading">
                  Hi There!{" "}
                  <span className="wave" role="img" aria-labelledby="wave">
                    👋🏻
                  </span>
                </h1>

                <h1 className="heading-name">
                  I'M
                  <strong className="main-name"> AUSTIN ELONGO</strong>
                </h1>

                <div className="heading-description">
                  <p>Crafting Digital Excellence | Web Developer | Mobile App Developer</p>
                  <p className="sub-description">Transforming innovative ideas into seamless digital experiences that drive business growth</p>
                </div>
              </div>

              <div className="cta-section">
                <Link to="/services" className="primary-cta">
                  Explore Services
                </Link>
                <Link to="/blog" className="secondary-cta">
                  Learn More
                </Link>
              </div>

              <div className="type-container">
                <Type />
              </div>

              <div className="scroll-indicator">
                <FaArrowDown className="scroll-arrow" />
                <span>Scroll to discover more</span>
              </div>
            </Col>

            <Col md={5} className="home-image animate__animated animate__fadeInRight">
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid floating-image"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
